<template>
  <form @submit.prevent="login">
    <div class="entry-form__list">
      <div class="entry-form__item">
        <input
          v-model.trim.lazy="$v.email.$model"
          :class="{
            error: $v.email.$error || sendError,
            valid: email && !$v.email.$error,
          }"
          class="input-entry"
          placeholder="E-mail"
          required
          type="email"
        />
        <div v-if="$v.email.$error" class="error-list__item">
          {{ $t("required_field") }}
        </div>
      </div>
      <div class="entry-form__item">
        <input
          v-model.trim.lazy="$v.password.$model"
          :class="{
            error: $v.password.$error || sendError,
            valid: password && !$v.email.$error,
          }"
          :placeholder="$t('auth.password')"
          :type="inputType"
          class="input-entry password-field"
          required
          autocomplete="on"
        />
        <div v-if="$v.password.$error" class="error-list__item">
          {{ $t("auth.wrong_registration_pass") }}
        </div>
        <div class="switch-password">
          <button type="button" class="btn btn-empty" @click="toggleType">
            <svg v-if="inputType === 'password'" v-svg role="presentation" size="0 0 18 16" symbol="view-1"></svg>
            <svg v-else v-svg role="presentation" size="0 0 18 16" symbol="view-2"></svg>
          </button>
        </div>
      </div>
      <template v-if="g2a_need">
        <input required class="input-entry mt-3 mb-2" autocomplete="off" type="text" v-model="g2a" placeholder="G2A Code *" />
      </template>
      <vue-recaptcha class="pt-2 pb-4 d-flex justify-content-center w-100" sitekey="6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW" size="normal" theme="light" tabindex="0" :loadRecaptchaScript="true" @verify="recaptchaVerified" @expire="recaptchaExpired" @fail="recaptchaFailed" ref="vueRecaptcha" />
      <div class="entry-form__item _submit">
        <button class="btn _w100" type="submit">
          {{ $t("auth.sign_in") }}
        </button>
      </div>
      <div class="entry-form__item _privacy mb-0">
        <router-link to="/reset-password">{{ $t("auth.forgot_password") }} </router-link>
      </div>
    </div>
    <alert v-if="$alert.title" />
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import AuthService from "@/api/serviceAuth";
import { mapActions, mapGetters } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Login",
  mixins: [validationMixin],
  components: { VueRecaptcha },
  data() {
    return {
      inputType: "password",
      email: "",
      password: "",
      recaptcha_response: "",
      sendError: false,
      g2a: "",
      g2a_need: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    capChaKey() {
      return "6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW";
    },
  },
  created() {
    if (this.$route.query.secret) {
      AuthService.getTokenAdmin(this.$route.query.secret).then((res) => {
        if (res && res.status === 200) {
          localStorage.setItem("guestToken", res.data.token);
          this.$router.go(0);
        }
      });
    }
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    async login() {
      this.$v.$touch();
      this.sendError = false;
      if (this.$v.$invalid) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
        });
        return;
      }

      if (!this.recaptcha_response) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("toast_error_captcha"),
        });
        return;
      }
      const params = {
        email: this.email,
        password: this.password,
        recaptcha_response: this.recaptcha_response,
        g2a: this.g2a,
      };
      AuthService.login(params).then(
        (resp) => {
          if (resp.data.type === "Error") {
            this.$alert({
              title: this.$t("sent_error"),
              text: resp.data.message,
            });
            this.sendError = true;
          } else if (resp.type === "Error" && resp.message === "Wrong G2A code") {
            this.$alert({
              title: this.$t("auth.enter_g2a"),
            });
            this.g2a_need = true;
            this.$refs.vueRecaptcha.reset();
          } else if (resp.data.token) {
            localStorage.setItem("authToken", resp.data.token);
            localStorage.setItem("refreshToken", resp.data.refresh_token);
            
            const redirectPath = this.$route.query.redirect || "/dashboard";

            this.$router.push(redirectPath);
            this.setUser();
          } else {
            this.$alert({
              title: this.$t("sent_error"),
              text: resp.message,
            });
            this.recaptchaFailed();
          }
        },
        (reason) => {
          console.log("error ======= ", reason);
        }
      );
    },
    toggleType() {
      this.inputType === "password" ? (this.inputType = "text") : (this.inputType = "password");
    },
    recaptchaVerified(response) {
      this.recaptcha_response = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
    },
  },
};
</script>

<style>
.grecaptcha-badge {
  bottom: 80px !important;
}
</style>

<style lang="scss" scoped>
.switch-password .btn.btn-empty {
  padding: 0;
}
</style>
